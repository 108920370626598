
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "@/lib/abpbase";
import PageRequest from "@/store/entities/page-request";
import PeopleFind from "../../../components/People/people-find.vue";
import Envio from "../../../store/entities/OZONE/envio";
import WidgetCounter from "@/components/Muse/Widgets/WidgetCounter.vue";
import XLSX, { read } from "xlsx";
import SelectCampana from "../campana/selectCampana.vue";
import Campo from "@/store/entities/OZONE/campo";
import Charlie from "@/store/entities/OZONE/charlie";
import CharlieRegistro from "@/store/entities/OZONE/charlieRegistro";
import CharlieCampo from "@/store/entities/OZONE/charlieCampo";
import Participacion from "@/store/entities/OZONE/participacion";

class PageCamposRequest extends PageRequest {
  campanaId: number;
}

@Component({
  components: {
    PeopleFind,
    WidgetCounter,
    SelectCampana,
  },
})
export default class ImportCharlie extends AbpBase {
 
  fileList = [];
  fileData: string = "";
  actions = [];

  fieldMapping: {
    fName: string;
    fKey: string;
    mId: number;
    mName: {
      label: string;
      key: string;
    };
    mOrder: number;
    opts: number[];
  }[] = [];

  groupedFields = [];
  referenceMapping = [];
  fields = [];
  mapping = [];
  records = [];
  camposCampList = [];
  camposSelect = [];
  campana = null;
  percent = 0;
  total = 0;

  charlie: Charlie = new Charlie;
  current = 0;
  wrongFormat = false;
  fetching: boolean = false;
  checkCabeceras: boolean = false;

  labelCol = { span: 4 };
  wrapperCol = { span: 14 };

  setCabeceras() {
    if(!this.checkCabeceras){
      this.checkCabeceras = true;
    }
    else{
      this.checkCabeceras = false;
    }
  }

  next() {
    this.current = this.current + 1;
  }

  prev() {
    if (this.current == 1) {
      this.fileList = [];
    }

    this.current = this.current - 1;
  }

  reset() {
    location.reload();
  }

  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }

  beforeUpload(file) {
    this.fileList = [file];
    this.readFile();
    return false;
  }

  //Al cargar el archivo entra aquí, lee el archivo y saca las cabeceras
  readFile() { 
    if (this.fileList && this.fileList.length > 0) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, 
        { 
          type: "string",
          raw: true, 
          cellDates: false, 
          cellText: true 
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        
        const dataExcel = XLSX.utils.sheet_to_json(ws, 
        {
          defval: '',
          blankrows: true
        });
        this.total = dataExcel.length;

        //Recorre cada línea
        dataExcel.forEach((value, index) => {
          //Recorre dato de cada cada línea
          for (var key in dataExcel[index] as any) {
            //Busca y reemplaza los caracteres raros
            for (let i = 0; i < this.caracteres.length; i++) {
              value[key] = value[key].replaceAll(/\s+/g, ' ').trim();
              value[key] = value[key].replaceAll(this.caracteres[i].erroneo, this.caracteres[i].correcto);
            }              
            
            //Obtiene las cabeceras
            if (index == 0) {
              this.fieldMapping.push({
                fKey: key,
                fName: key,
                mId: 0,
                mName: {
                  key: "",
                  label: "",
                },
                mOrder: 0,
                opts: [],
              });    
            }            
          }

          this.records.push(value);
          this.percent = ((index + 1) * 100) / this.total;
        });
      };

      reader.readAsText(this.fileList[0], 'UTF-8');
    }
  }

  //Genera el array con los campos de cada participación
  mapDatos() {
    let registrosFichero: Array<CharlieRegistro> = new Array<CharlieRegistro>();   

    //Recoge cada registro en un array distinto y deja fuera
    //los campos cuya cabecera no ha sido mapeada
    this.records.forEach((r) => {
      let registros: CharlieRegistro = new CharlieRegistro();

      for (var gf in this.groupedFields) {
        let arr = this.groupedFields[gf];
        let value = "";

        let campo = this.camposSelect.filter(
          (e) => e.value == arr[0].mName.key
        )[0];

        //Si no se ha seleccionado el campo como "nomap"
        //se crea el objeto y se asigna como "nomap" para que no lo coja
        if (campo == undefined){
          campo = Object();
          campo.value = "nomap"
        }

        if (campo.value !== "nomap") {
          arr.forEach((element) => {
            value = r[element.fName];
          });            
          let campoRegistro = new CharlieCampo();
          campoRegistro.nombreCampo = campo.value,
          campoRegistro.valorCampo = value 
          registros.listaCampos.push(campoRegistro);

        } else {
          //Si el campo es "nomap" no coge ni el campo ni el dato
        }
      }

      registrosFichero.push(registros);
    });
    this.charlie.registrosFichero = registrosFichero;
    
    //Si no ha mapeado ningún campo
    if (registrosFichero[0].listaCampos.length != 0){          
      //Esto pasa de página
      this.current = this.current + 1;
    } else {
      this.info()
      this.current = this.current - 1;
    }
  }

  //Mapea los campos del fichero con los seleccionados en un array (this.groupedFields)
  //que contiene objetos this.fieldMapping (arrays)
  setMapping(v) {
    var result = this.fieldMapping.reduce(function (r, a) {
      r[a.mName.key] = r[a.mName.key] || [];
      r[a.mName.key].push(a);
      return r;
    }, Object.create(null));

    this.groupedFields = result;
    this.current = this.current + 1;
  }

  //Recoge los campos de la campaña
  @Watch("campana") async getCampanaCampos() {
    this.fetching = true;

    var camposRequest = new PageCamposRequest();
    camposRequest.campanaId = this.campana.id;
    camposRequest.maxResultCount = 50;
    camposRequest.skipCount = 0;

    this.charlie.campId = this.campana.id;
    this.charlie.nomCamp = this.campana.nombre;
    this.charlie.ekonCamp = this.campana.axapta;

    await this.$store.dispatch({
      type: "campana/getAllCampos",
      data: camposRequest,
    });    
    this.camposCampList = this.$store.state.campana.camposList;

    let camposSelect = this.$store.state.campana.camposList.map((campo) => ({
      text: campo.nombre.toUpperCase(),
      value: campo.nombre,
      custom: true,
      selected: false,
      id: campo.id,
    }));

    var camposMap = new Array<Campo>();

    for (var campo in camposMap) {
      camposSelect.push({
        text: campo.toUpperCase(),
        value: campo,
        custom: false,
        selected: false,
        id: 0,
      });
    }

    //Crea un objeto "Participación" para después recorrer sus atributos
    //y añadir sus nombres al select del mapeo de cabeceras
    var p = new Participacion();
    p.nombre = "";
    p.apellido1 = "";
    p.apellido2 = "";
    p.email = "";
    //Recorre el objeto y añada al select
    for (var ca in p) {
      camposSelect.push({
        text: ca.toUpperCase(),
        value: ca.substring(0,1).toUpperCase() + ca.substring(1),
        custom: false,
        selected: false,
        id: 0,
      });
    }

    camposSelect.push({
      text: "SIN MAPEAR",
      value: "nomap",
      custom: false,
      selected: false,
      id: 0,
    });

    this.camposSelect = camposSelect;
    this.fetching = false;
  }

  checkMap(value) {
    var arr = this.fieldMapping;
    arr.forEach(function (part, index) {
      var count = arr.reduce(
        (acc, cur) =>
          cur.mName.key == part.mName.key &&
          part.mName.key &&
          part.mName.key != "nomap"
            ? ++acc
            : acc,
        0
      );
      arr[index].opts = [];
      if (count > 1) {
        for (var i = 1; i < count + 1; i++) {
          arr[index].opts.push(i);
        }
      }
    });
    this.fieldMapping = arr;
  }

  getCount(field) {
    let prcnt = 0;
    let count = 0;
    field.forEach((element) => {
      let key = field[0].fKey;
      let kCount = this.records.filter(
        (obj) => obj[key] && obj[key] != ""
      ).length;
      prcnt += (kCount * 100) / this.records.length;
      count++;
    });

    return (prcnt / count).toFixed(0);
  }

  getExample(field) {
    let ex = "";
    let row = this.records[0];
    field.forEach((element) => {
      ex += row[element.fKey];
    });

    return ex;
  }

  //Redireige a envios
  //Cuando se cree "Ficheros de impresión" deberá redirigir allí
  goToEnvios() {
    this.$router.push({ name: "envioslist" });
  }

  info() {
    const h = this.$createElement;
    this.$info({
      title: 'Datos necesarios',
      content: h('div', {}, [
        h('p', '¡Nombre y appellido son obligatorios!'),
      ]),
      onOk() {},
    });
  }

  //LLama a la API Charlie y le pasa los datos del fichero ara que los procese y los guarde
  loadCharlie() {
    (this.$refs.ruleForm as Vue & { validate: (valid) => Promise<boolean> })
    .validate(async (valid) => {
      if (valid) {
        this.actions.push("Inicio importación");
        
        this.charlie.nomFichOrigen = this.fileList[0].name;
        this.charlie.observaciones = this.form.desc;
        this.charlie.origenFichero = this.form.name;

        await this.$store
          .dispatch({
            type: "charlie/postProcesaDatosCharlie",
            data: this.charlie,
          })
          .then(() => {
            this.actions.push("Transferncias cargadas correctamente");
            this.next();
          })
          .catch(() => {
            this.actions.push("No se han podido cargar las transferencias");
          });
      } else {
        return false;
      }
    });
  }

  form = {
    name: "",
    mailing: "",
    desc: "",
    envtype: 1,
    fecenv: "",
  };

  rules = {
    name: [
      {
        required: true,
        message: "El origen es obligatorio",
        trigger: "blur",
      },
    ],
    desc: [
      { min: 0, max: 5000, message: "Máximo 5000 caracteres", trigger: "blur" },
    ],
  };

  steps: { title; content }[] = [
    {
      title: "Cargar Fichero",
      content: "First-content",
    },
    {
      title: "Mapeo Campaña",
      content: "First-content",
    },
    {
      title: "Resumen & Validación",
      content: "Second-content",
    },
    {
      title: "Carga",
      content: "Last-content",
    },
    {
      title: "Finalizado",
      content: "Last-content",
    },
  ];

  caracteres = [
    {
      erroneo: "‰",
      correcto: "ë",
    },
    {
      erroneo: "ó",
      correcto: "Í",
    },
    {
      erroneo: "œ",
      correcto: "Ñ",
    },
    {
      erroneo: "œ",
      correcto: "Ñ",
    },
    {
      erroneo: "}",
      correcto: "Ñ",
    },
    {
      erroneo: "#",
      correcto: "Ñ",
    },
    {
      erroneo: "ù",
      correcto: "Ñ",
    },
    {
      erroneo: "ô",
      correcto: "Ñ",
    },
    {
      erroneo: "—",
      correcto: "Ñ",
    },
    {
      erroneo: "¥",
      correcto: "Ñ",
    },
    {
      erroneo: "¤",
      correcto: "É",
    },
    {
      erroneo: "¦",
      correcto: "ª",
    },
    {
      erroneo: "ö",
      correcto: "Ó",
    },
    {
      erroneo: "÷",
      correcto: "Ó",
    },
    {
      erroneo: "",
      correcto: "É",
    },
    {
      erroneo: "§",
      correcto: "º",
    },
    {
      erroneo: "µ",
      correcto: "Á",
    },
    {
      erroneo: "?",
      correcto: "º",
    },
    {
      erroneo: "½",
      correcto: "Ë",
    },
    {
      erroneo: "¾",
      correcto: "É",
    },
    {
      erroneo: "€",
      correcto: "Ç",
    },
    {
      erroneo: "ê",
      correcto: "É",
    },
    {
      erroneo: "š",
      correcto: "U",
    },
    {
      erroneo: "‚",
      correcto: "É",
    },
    {
      erroneo: "¡",
      correcto: "Í",
    },
    {
      erroneo: "•",
      correcto: "Í",
    },
    {
      erroneo: "‹",
      correcto: "Á",
    },
    {
      erroneo: "¢",
      correcto: "ó",
    }
  ];
}
